
export default {
  async asyncData({ params }) {
    return {
      slug: params.slug
    }
  },
  layout(context) {
    const staticPages = context.store.state.base.meta.generalConfig.staticPages;
    const foundPage = staticPages.find(p => p.path === context.route.path);
    if (!foundPage) {
      return 'base';
    }
    return foundPage.template ? foundPage.template.toLowerCase() : 'base'
  },
  computed: {
    isBrochure() {
      const staticPages = this.$store.state.base.meta.generalConfig.staticPages;
      const path = this.$route.path
      const foundPage = staticPages.find(p => p.path === path);
      return !!foundPage && foundPage.type === 'Brochure';
    }
  }
}
